<template>
  <div
      v-show="show"
      class="view-spinner"
      :class="{'fullscreen-spinner': fullscreen}">
    
    <q-spinner-ios
        color="homeward-purple"
        size="5em">
    </q-spinner-ios>
  
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'ViewSpinner',
  props: {
    show: {
      type: Boolean,
      require: true
    },
    fullscreen: {
      type: Boolean,
      default: false
    }
  }
});
</script>

<style lang="scss" scoped>

.view-spinner {
  min-height: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.fullscreen-spinner {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
}

</style>